.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, $btn-border-radius-xs);

  min-width: 46px;
}

.btn:hover,
.btn:active,
.btn:active:focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}
