.rst__tree > div,
.rst__tree:focus,
.rst__tree:active,
.ReactVirtualized__Grid:focus,
.ReactVirtualized__Grid:active {
  outline: none;
}

.rstcustom__highlightTopLeftCorner,
.rstcustom__highlightBottomLeftCorner,
.rstcustom__highlightLineVertical {
  display: none;
}


.rstcustom__rowWrapper {
  display: flex;
  align-items: center;

  &:hover {
    opacity: 1;
  }

  & > div:nth-child(1) {
    width: 100%;

    & .rstcustom__DirectoryFile {
      border: 1px solid transparent;
      padding-right: 11px;

      & .rstcustom__rowLabel {
        margin-right: auto;
      }
    }

    & .rstcustom__row.rstcustom__DirectoryFile,
    & .rstcustom__row.rstcustom__DirectoryNode {
      width: 100%;
      padding: 2px 10px;
      border-radius: 5px;
    }

    & .rstcustom__row.rstcustom__DirectoryNode {
      border: 1px solid $secondary;

      & .rstcustom__rowLabel {
        margin-right: auto;
      }
    }

    & .rstcustom__row.rstcustom__rowSearchMatch {
      -webkit-box-shadow: none;
      box-shadow: none;

      border: 1px solid $info;
    }

    & .rstcustom__row.rstcustom__rowSearchMatch.rstcustom__rowSearchFocus {
      border: 1px solid $primary;
    }
  }

  & .rstcustom__toolbarButton {
    display: flex;
  }
}

