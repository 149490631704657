@import "~react-day-picker/lib/style.css";

.DayPickerInput {
  & .DayPicker-wrapper {
    & .DayPicker-Months {
      & .DayPicker-Body {
        & .DayPicker-Week {
          & .DayPicker-Day {
            min-width: 40px;
            min-height: 40px;
          }
        }
      }
    }
  }

  &.DayPickerInvalid ~ .invalid-feedback {
    display: block;
  }
}
