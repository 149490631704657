@import "/home/pdeadmin/pde/pde_dm_spa/source/src/resources/assets/styles/_variables.scss";
.DocumentsTreeToolbar {
  form {
    label {
      margin: 0;
      margin-right: 5px;
    }

    input {
      min-width: 300px;
      margin-right: 5px;
    }
  }
}
