@import "/home/pdeadmin/pde/pde_dm_spa/source/src/resources/assets/styles/_variables.scss";
.FormModalBase {
  & :global(.modal-content) {
    & > :global(.card) {
      margin-bottom: 0;

      & > :global(.card-header) {
        & > :global(.close) {
          margin-left: auto;
        }
      }

      & :global(.card-body) {
        textarea {
          resize: none;
          height: 140px;
        }

        pre {
          white-space: pre-wrap;
          white-space: -moz-pre-wrap;
          white-space: -o-pre-wrap;
          word-wrap: break-word;
        }

        & :global(.form-group),
        & :global(.input-group) {
          display: flex;
          flex-direction: row;
          align-items: center;

          & > label {
            display: flex;
            align-self: self-start;
            align-items: center;
            height: 38px;
            max-width: 120px;
            width: 100%;
            margin-bottom: 0;
          }
        }

        & :global(.form-group) {
          & :global(.InputContainer),
          & :global(.DayPickerInput) {
            width: 100%;
          }
        }
      }
    }
  }
}
