@import "/home/pdeadmin/pde/pde_dm_spa/source/src/resources/assets/styles/_variables.scss";
.ActionsFormatter {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;

  & :global(.btn):not(:last-child) {
    margin-right: 5px;
  }
}
