@import "/home/pdeadmin/pde/pde_dm_spa/source/src/resources/assets/styles/_variables.scss";
.security {
  & .container {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;

    & .form {
      margin-top: 20px;
    }
  }


  & .main {
    //padding: 10px 0px 0px;



    //& > :global(.card) {
    //  max-width: 600px;
    //  width: 100%;
    //  margin: 0 auto;
    //}
  }
}