.react-select-container {
  &.react-select-invalid  ~ .invalid-feedback{
    display: block;
  }

  & .react-select__control--is-disabled {
    background-color: #e9ecef;
  }

  & .react-select__value-container {
    & .react-select__single-value {
      color: rgb(73, 80, 87);
    }
  }
}
