@import "/home/pdeadmin/pde/pde_dm_spa/source/src/resources/assets/styles/_variables.scss";
.DataTable {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  & :global(.card),
  & :global(.card-body) {
    width: 100%;
  }

  & .CardTable {
    & .TableContainer {
      overflow-x: scroll;
    }
  }

  & .FilterForm {
    & form {
      & :global(.card-body) > div {
        & > div {

          & > label {
            flex: 1;
          }

          & > :global(.form-control),
          & > :global(.form-group) {
            flex: 5;
          }

          & > :global(.form-group),
          & > :global(.form-group) > :global(.InputContainer),
          & > :global(.form-group) > :global(.InputContainer) > :global(.DayPickerInput) {
            width: 100%;
            margin-bottom: 0;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      & form {
        & :global(.card-body) > div {
          flex-direction: column;

          & > div:not(:last-child) {
            margin-bottom: 5px;
          }

          & > div {
            & > :global(.form-control),
            & > :global(.form-group) {
              flex: 3;
            }
          }
        }
      }
    }
  }
}
