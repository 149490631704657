@import "/home/pdeadmin/pde/pde_dm_spa/source/src/resources/assets/styles/_variables.scss";
.LoadingDot {
  display: inline-block;
  width: 1em;
  height: 1em;

  border-radius: 1em;

  animation: dot-animation 1s ease-in-out infinite;
}

@keyframes dot-animation {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
