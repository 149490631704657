@import "/home/pdeadmin/pde/pde_dm_spa/source/src/resources/assets/styles/_variables.scss";
.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #72747e;
  border-style: dashed;
  background-color: #ffffff;
  outline: none;
  transition: border .24s ease-in-out;

  &:focus,
  &:hover {
    border-color: #1fb841;
  }
}

.placeholder {
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: 0.5px;

  color: #72747e;

  & > span {
    font-weight: bold;
    cursor: pointer;
  }
}

.fileRoot {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0px 15px;
  margin-bottom: 5px;

  border-radius: 2px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.09);
}

.close {
  margin-left: auto;
  cursor: pointer;
}
