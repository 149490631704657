@import "coreui/coreui";


html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

button,
.bnt {
  outline: none !important;
}

.select-xs {
  height: 1.97em;
}