@import "/home/pdeadmin/pde/pde_dm_spa/source/src/resources/assets/styles/_variables.scss";
.label-button {
  display: flex;
  justify-content: center;
  min-width: 100px;
  padding: 6px 30px;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  opacity: 0;
}
