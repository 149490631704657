@import "buttons";
@import "navbar";

@media (max-width: 991.98px) {
  .app-header {
    //position: relative;
  }
}

.app-header {
  background-color: $header-color;
  border-bottom: none;
}

.table-bordered {
  tr th:first-child {
    border-top-left-radius: 10px;
  }
}
