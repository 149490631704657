//@import "~@coreui/coreui/scss/bootstrap/variables";

// Core UI fonts
$coreui-icons-font-path: "~@coreui/icons/fonts/";

// Simple line icons fonts
$simple-line-font-path: "~simple-line-icons/fonts/";

// Font awesome web fonts
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts/";

// custom colors
$header-color: #772953;

// colors
$primary: #E95420;
$success: #38B44A;
$secondary: #AEA79F;
$danger: #DF382C;
$warning: #EFB73E;
$info: #17a2b8;
$light: #f0f3f5;
$dark: #2f353a;

// greys
$grey-100: #f0f3f5;
$grey-200: #e4e7ea;
$grey-300: #c8ced3;
$grey-400: #acb4bc;
$grey-500: #8f9ba6;
$grey-600: #73818f;
$grey-700: #5c6873;
$grey-800: #2f353a;
$grey-900: #23282c;

// toast
$rt-color-default: $primary;
$rt-color-info: $info;
$rt-color-success: $success;
$rt-color-warning: $warning;
$rt-color-error: $danger;


// xs styles
$border-radius-xs: .1rem;
$font-size-base: 1rem;
$font-size-xs:  $font-size-base * .700;
$line-height-xs: 1.5;
$input-btn-padding-y-xs:  .15rem;
$input-btn-padding-x-xs:  1rem;
$input-btn-font-size-xs:  $font-size-xs;
$input-btn-line-height-xs:  $line-height-xs ;

$btn-border-radius-xs: $border-radius-xs;
$btn-padding-y-xs:  $input-btn-padding-y-xs;
$btn-padding-x-xs:  $input-btn-padding-x-xs;
$btn-font-size-xs:  $input-btn-font-size-xs;
$btn-line-height-xs:  $input-btn-line-height-xs;


@import '~@coreui/coreui/scss/bootstrap/functions';
@import '~@coreui/coreui/scss/bootstrap/variables';
@import '~@coreui/coreui/scss/bootstrap/mixins';
