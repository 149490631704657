.fade-enter {
  max-height: 0;
  //opacity: 0;
}

.fade-enter-active {
  max-height: 164px;
  //opacity: 1;
  transition: all 500ms;
}

.fade-exit {
  max-height: 50px;
  opacity: 1;
}

.fade-exit-active {
  max-height: 0;
  opacity: 0;
  transition: all 500ms;
}
