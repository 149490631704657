.navbar-nav {
  .nav-link:hover,
  .nav-link:focus,
  .nav-link.open:hover,
  .nav-link.open:focus,
  .nav-link.active:hover,
  .nav-link.active:focus {
    color: #ffffff;
  }
}

.nav-item {
  & .dropdown-menu {
    padding: 0;
    border-radius: 0.25rem;

    & .dropdown-header {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }
}