@import "/home/pdeadmin/pde/pde_dm_spa/source/src/resources/assets/styles/_variables.scss";
.DocumentFormModal {
  & > :global(.modal-content) {
    & > :global(.card){
      & :global(.card-body) {
        & > :global(.row) {
          & .Accordion {
            z-index: unset;
          }
        }
      }
    }
  }
}
